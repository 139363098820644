import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Home = ({socket}) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const team_player=localStorage.getItem("team");

  let is_exist=false;

const join_room=()=>{navigate('/join');}
  const handleSubmit = (e) => {
    console.log(team_player)
    console.log(userName);
    e.preventDefault();
    localStorage.setItem('userName', userName);
    console.log(userName);
    



    socket.on('arr_users',async (users_list)=>{console.log(users_list)
      //check if the user not exist and create user

    users_list.forEach(element => {
      if(userName===element.userName){is_exist=true}
    });
    if(is_exist){alert("user already exist"); 
    console.log(userName);
    setUserName('  ')
    window.location.reload();
  }
    if(!is_exist){
       
         const enteredName=  prompt('Please enter your name')
      localStorage.setItem('enteredName', enteredName);
      socket.emit('refresh', {userName});
      socket.emit('count_add', {userName});
      socket.emit('add_player',{userName,enteredName,team_player});
    navigate('/chat');
  }
  
  })


socket.emit('newUser', { userName,socketID: socket.id});


  };
  return (
    <form className="home__container" onSubmit={handleSubmit}>
      <h2>create a new game and share your room name with friends to join</h2>
      <h2 className="home__header">create new game</h2>
      <label htmlFor="username">game id</label>
      <input
        type="text"
        minLength={6}
        name="username"
        id="username"
        className="username__input"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
      />
      <button className="home__cta">SIGN IN</button>
      <button className="home__cta" onClick={join_room}>join a game</button>

    </form>
    
  );
};

export default Home;