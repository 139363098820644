import React, { useState , useEffect } from 'react';

const Spa_fra_bar = ({socket,players_arr1,players_arr2}) => {
  const playerName=localStorage.getItem('player1');
  const playerTeam=localStorage.getItem('team');

    const[users,setUsers]=useState([]);


const arr=[];

   


    useEffect(() => {

        socket.on('newUserResponse', (data) => setUsers(data));
      }, [socket, users]);
  //create array of players
     
      
  

   


  return (
    <div  className="chat__sidebar" >
      <h2>users</h2>

      <div style={{fontSize:"85%"}}>
      <p>refresh the page to see all the users and messages...</p>

        <h4 className="chat__header">ROOM NAME</h4>
        <div className="chat__users">
        
          <p style={{fontSize:"98%",wordBreak:"break-all"}}>{localStorage.getItem('userName')}</p>

        </div>
      </div>

      <div style={{fontSize:"87%"}}>
        <div className="chat__users">
        <h4 style={{color:"black"}} className="chat__header">SPAIN</h4>



        {players_arr1.map(element=>(
         <p style={{fontSize:"75%",wordBreak:"break-all",marginTop:"20px"}}>{element}</p>

        ))}
        <h4 style={{color:"black"}} className="chat__header">FRANCE</h4>

         {players_arr2.map(element=>(
         <p style={{fontSize:"75%",wordBreak:"break-all",marginTop:"20px"}}>{element}</p>

        ))}



         
        </div>
      </div>

    </div>
  );
};

export default Spa_fra_bar;