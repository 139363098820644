import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Join = ({socket,team}) => {
  
    const navigate = useNavigate();
    const team_player=localStorage.getItem("team");


    const [userName, setUserName] = useState('');
    let is_exist=false;

    const handleSubmit = (e) => {
      console.log(userName);
  
      e.preventDefault();
      localStorage.setItem('userName', userName);
      
  
      
      socket.emit('joinUser', { userName, socketID: socket.id });

      socket.on('arr_join',(users_list)=>{console.log(users_list)
        //check if the user not exist and create user
      users_list.forEach(element => {
        if(userName===element.userName){
            
            is_exist=true;
        }
      });
      if(is_exist){
      console.log(userName);

      const enteredName = prompt('Please enter your name');
      localStorage.setItem('enteredName', enteredName);
      console.log("ent"+enteredName);

      socket.emit('refresh_join', {userName});
      socket.emit('add_player',{userName,enteredName,team_player});

      navigate('/chat');
    }
      if(!is_exist){
        alert("user not exist"); 
        setUserName('  ');
        window.location.reload();

      }
  
    })
 
  
    };
    return (
      <form className="home__container" onSubmit={handleSubmit}>
        <h2 className="home__header">join game</h2>
        <label htmlFor="username">game id</label>
        <input
          type="text"
          minLength={6}
          name="username"
          id="username"
          className="username__input"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <button className="home__cta">join</button>
  
      </form>
      
    );
  };
  
  export default Join;