
const Spain_france = () => {



    (function(d, s, id) { var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s);
         js.id = id; js.src = 'https://www.scorebat.com/embed/embed.js?v=arrv'; fjs.parentNode.insertBefore(js, fjs); }(document, 'script', 'scorebat-jssdk'));



    return(
        <iframe src='https://www.scorebat.com/embed/matchview/1547931/' frameborder='0' width='560' height='51%' allowfullscreen allow='autoplay; fullscreen' 
        style={{width:"100%",height:"51%",overflow:"hidden", class:"_scorebatEmbeddedPlayer_"}}></iframe>
    )
}
export default Spain_france;