import { all } from 'axios';
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Socket,io } from 'socket.io-client';


const ChatBody = ({messages,lastMessageRef,typingStatus,socket }) => {
  
  const player_name=localStorage.getItem("enteredName");
 
 

  messages.forEach(element=>{

    console.log("element player before:"+element.player);

  })
 
  socket.on('arr_user_messages',(user_messages_list)=>{
    messages.forEach(element=>{
      console.log("element player first:"+element.player);

    })
    user_messages_list.map(element => {
     
     messages.push(element);
     console.log("element player inside:"+element.player);

    });  
    messages.forEach(element => {
      console.log("element player:"+element.player);

    }
      );

  
      user_messages_list.forEach(element=>{
        console.log("element player last:"+element.player);
  
      })

  })




  const navigate = useNavigate();

  

  const handleLeaveChat = () => {
    
    localStorage.removeItem('userName');
    navigate('/');
    window.location.reload();
    
  };



  return (
< >
      <header className="chat__mainHeader">
        <p>chat</p>

      </header>



      <div className="message__container" >
        {messages.map((message) =>
          ((message.player_name === localStorage.getItem('enteredName'))||(message.player === localStorage.getItem('enteredName'))) ? (
            <div className="message__chats" key={message.id}>
              <p className="sender__name">{message.player_name}</p>
              <p className="sender__name">{message.player}</p>
              <div className="message__sender">
                <p>{message.text}</p>

              </div>
            </div>
          ) : (
            <div className="message__chats" key={message.id}>
              <p>{message.player_name}</p>
              <p>{message.player}</p>
              <div className="message__recipient">
              <p>{message.text}</p>

              </div>
            </div>
          )
        )}
   
        <div className="message__status">
        </div>
        
        <div ref={lastMessageRef} />

      </div>

  
    </>
  );
};

export default ChatBody;