import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState,useRef } from 'react';


const Spain_France_Card = ({socket}) => {

  const navigate = useNavigate();



  const create_1=()=>{
    localStorage.setItem('team', 1);
    navigate('/Home');
  }

  const join_1=()=>{
    localStorage.setItem('team', 1);
    navigate('/Join');
  }


  const create_2=()=>{
    localStorage.setItem('team', 2);

    navigate('/Home');
  }

  const join_2=()=>{
    localStorage.setItem('team', 2);

    navigate('/Join');
  }


    return (
        <div className="game_card" >
        <div style={{position:"absolute", textAlign:"center"}}> <h3>who will win ?</h3></div> 

   <div className="game_card_teams">
   <p>SPAIN</p>

<span className="circle_team" style={{backgroundColor:"yellow"}}></span>
<div style={{width:"40%", display: "flex",marginLeft:"20%"}}>
<button style={{height:"40pt"}} className="choose_game_btn"  onClick={create_1}>create new game</button>
<button style={{height:"40pt",marginLeft:"5pt"}} className="choose_game_btn"  onClick={join_1}>join game</button>
</div>
   </div>

   <div className="game_card_teams" >
<p>FRANCE</p>
<span className="circle_team" style={{backgroundColor:"blue"}}></span>
<div style={{width:"40%",display: "flex",marginLeft:"20%"}}>
<button style={{height:"40pt"}} className="choose_game_btn"  onClick={create_2}>create new game</button>
<button style={{height:"40pt",marginLeft:"5pt"}} className="choose_game_btn"  onClick={join_2}>join game</button>
</div>
   </div>

        </div>
      );
    };
    
    export default Spain_France_Card;