import React, { useEffect, useState,useRef } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';

import Ger_sco_bar from '../bars/Ger_sco_bar';
import Spa_fra_bar from '../bars/Spa_fra_bar';
import ChatBody from '../ChatBody';
import ChatFooter from '../ChatFooter';
import Game1 from '../Games/Game1';
import Germany_scotland from '../Games/Germany_scotland';
import Spain_france from '../Games/Spain_france';

const Spain_fra_page = ({ socket}) => {
    const [messages, setMessages] = useState([]);
    const [typingStatus, setTypingStatus] = useState('');
    const lastMessageRef = useRef(null);
    const[players_arr1,setPlayer1]=useState([]);
    const[players_arr2,setPlayer2]=useState([]);



    useEffect(() => {
     
      socket.on('messageResponse', (data) =>   {
        
if(data.name==localStorage.getItem('userName')){
  setMessages((messages)=>[ ...messages,data])} })  
  messages.forEach(element=>{
    console.log("mess_resp"+element.text);

  })



    }, [messages]);


    useEffect(() => {
      socket.on('arr_user_messages', (data) =>{
        data.forEach(element => {
          setMessages((messages)=>[...messages,element])
        });
        });

    },[])

    

      
    useEffect(() => {
   
        messages.forEach(element => {
          console.log("arr_user"+element.text);
        });
    }, [ socket,messages]);

      useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, [socket,messages]);
    
      useEffect(() => {
        socket.on('typingResponse', (data) => setTypingStatus(data));
      }, [socket]);

      useEffect(() => {
      socket.on('arr_players',players_list=>{
        for(const element of players_list){

          if(element.team==1){
           // players_arr1.push(element.player);
            setPlayer1(players_arr1=>[...players_arr1,element.player])
          }
          else if(element.team==2){
           // players_arr2.push(element.player);
            setPlayer2(players_arr2=>[...players_arr2,element.player])
          }
        }

      })
   
    },[])
    

  return (

    <div  className="chat" >

      <Spa_fra_bar socket={socket} players_arr1={players_arr1} players_arr2={players_arr2}/>


      <div className="chat__main" >

      <Spain_france />

        <ChatBody    socket={socket} messages={messages}  lastMessageRef={lastMessageRef} typingStatus={typingStatus}   />

        <ChatFooter socket={socket}/>
      </div>

    </div>
  );
};

export default Spain_fra_page;