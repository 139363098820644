import React, { useState,useEffect } from 'react';
const p=localStorage.getItem('enteredName');

const ChatFooter = ({socket}) => {
  const [message, setMessage] = useState('');


  const handleTyping = () =>
    socket.emit('typing', `${localStorage.getItem('userName')} is typing`);



  const handleSendMessage = (e) => {
    e.preventDefault();
    if(message){
    if (message.trim() && localStorage.getItem('userName')) {
        socket.emit('message', {
          
          text: message,
          name: localStorage.getItem('userName'),
          player_name: p,
          id: `${socket.id}${Math.random()}`,
          socketID: socket.id,

        });
        

    }}
    setMessage('');
  };
  return (
    <div className="chat__footer">
      <form className="form" onSubmit={handleSendMessage}>
        <input
          type="text"
          placeholder="Write message"
          className="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleTyping}

        />
        <button className="sendBtn">SEND</button>
      </form>
    </div>
  );
};

export default ChatFooter;