import { useNavigate } from 'react-router-dom';
import Game_card1 from './Game_card1';
import Germany_scotland_card from './cards/Germany_scotland_card';
import Spain_France_Card from './cards/Spain_France_Card';

const Main_page = ({socket}) => {

    const navigate = useNavigate();

    return (
        <div className="main_page">
            <div className="main_page_header">
              <h1 style={{marginRight:"17%"}}>choose a game </h1>
            </div>
            <div className="games_main">
          <Game_card1 socket={socket} />
          <Germany_scotland_card socket={socket} />
          <Spain_France_Card socket={socket} />

            </div>

        </div>
      );
    };
    
    export default Main_page;