import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Join from './Join';
import ChatPage from './ChatPage';
import Spain_fra_page from './pages/Spain_fra_page';
import Germ_sco_page from './pages/Germ_sco_page';
import socketIO from 'socket.io-client';
import { useEffect } from 'react';
import Main_page from './Main_page';

var socket = socketIO.connect('https://serv.hthp.site:443');


function App() {
 // useEffect(() => {}
 // , [socket, messages])

  return (
    <div className="App">
 
        <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={<Main_page socket={socket} />}></Route>
          <Route path="/Home" element={<Home socket={socket} />}></Route>
          <Route path="/join" element={<Join socket={socket} />}></Route>
          <Route path="/chat" element={<Spain_fra_page socket={socket} />}></Route>

        </Routes>
      </div>
    </BrowserRouter>

       
    </div>
  );
}
 
export default App;
